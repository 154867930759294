<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/record-keep-order"
    entity-name="维保单"
    :add-btn="false"
    hide-show-detail
    show-row-num
    :action="action"
    :rules="rules"
    :columns="columns"
    :before-edit="beforeEdit"
    :before-update="beforeUpdate"
    :modal-width="640"
    :modal-form="{layout: 'horizontal', labelAlign: 'left', labelCol: {span: 4}, wrapperCol: {span: 20}}"
    :search-key-type="{name: 1}">
    <template v-slot:query="{form}">
      <a-form-model-item label="">
        <a-input-search v-model="form.name" placeholder="输入设备名称查询"></a-input-search>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{form}">
      <a-form-model-item label="维保单号" prop="num">
        <a-input v-model="form.num" placeholder="请输入维保单号" disabled></a-input>
      </a-form-model-item>
      <a-form-model-item label="维保状态" prop="status">
        <a-select
          style="width: 100%"
          placeholder="请选择"
          :options="statusOptions"
          disabled
          v-model="form.status">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备名称" prop="keep_name">
        <a-input v-model="form.keep_name" placeholder="请输入设备名称" disabled></a-input>
      </a-form-model-item>
      <a-form-model-item label="维保时间" prop="time">
        <a-range-picker
          v-model="form.time"
          :placeholder="['开始时间', '结束时间']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          disabled=""/>
      </a-form-model-item>
      <a-form-model-item label="文件上传" prop="file">
        <file-upload :limit="9"></file-upload>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import {getKeyTitle} from "../../common/js/tool";

    const statusOptions = [
        { key: 0, title: "未完成" },
        { key: 1, title: "已完成" },
    ]

    export default {
        name: "MaintenanceOrder",
        data() {
            return {
                columns: [
                    { title: '维保单编号', dataIndex: 'num' },
                    { title: '维保单状态', dataIndex: 'status', customRender: text => getKeyTitle(statusOptions, text) },
                    { title: '设备名称', dataIndex: 'keep',  customRender: text => text?.name || "--" },
                    { title: '维保时间', dataIndex: 'start_time', customRender: (text, record) => `${text} 至 ${record.end_time}` },
                ],
                statusOptions,
                rules: {
                    file: [{ required: true, message: '请上传文件', trigger: 'change' }],
                },
                methodOptions: [
                    { label: "企业微信通知", value: 1 },
                    { label: "短信通知", value: 2 },
                    { label: "语音电话通知", value: 3 },
                ]
            }
        },
        computed: {
            action() {
                return this.$route.meta.action;
            },
        },
        methods: {
            beforeEdit(form, callback) {
                if(form.id) {
                    form.keep_name = form.keep?.name || "--";
                    form.time = [form.start_time, form.end_time]
                }
                callback();
            },
            beforeUpdate(form, callback) {
                if(form.id) {
                    callback({
                        file: JSON.stringify(form.file)
                    });
                }
            }
        }
    }
</script>

<style lang="less">

</style>
